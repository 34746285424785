.about-container {
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}
.about-me {
  width: 80vw;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
}
.section-header {
  font-size: 30px;
  margin: 10px 10px 10px 20px;
  align-self: center;
}
.sub-header {
  font-size: 20px;
  margin: 10px 10px 10px 30px;
}
.about-paragraph,
.my-stack {
  margin-left: 35px;
  font-size: 18px;
}
.my-stack {
  font-weight: bold;
  margin-top: 15px;
}
