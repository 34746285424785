.header {
  min-height: 10vh;
  width: 100vw;
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  background-color: #6c757d;
}
@media (max-width: 768px) {
  .header {
    background-color: black;
  }
}
.link {
  font-size: 16px;
  color: #e9ecef;
  text-decoration: none;
  padding: 15px 15px;
  float: right;
}
