.projects-container {
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}
.project {
  width: 80vw;
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  background-color: #6c757d;
  padding: 30px;
  margin: 20px;
}
.project-pic {
  width: 40vw;
  height: auto;
  margin: 10px;
  object-fit: contain;
}
.project-description {
  width: 35vw;
  margin: 10px;
}
.project-link-buttons {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}
.project-link-buttons button {
  margin: 10px;
}
