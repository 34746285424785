.contact-container {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}
.page-title {
  font-size: 40px;
  margin: 40px 0px 20px 0px;
}
.icon {
  text-decoration: none;
  height: 40px;
  width: 40px;
  margin: 20px;
}
