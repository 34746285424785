.App {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  font-family: "Raleway", sans-serif;
  background-color: black;
  color: white;
  min-height: 100vh;
}
.title {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}
.App h1 {
  font-family: "Sigmar One", cursive;
}
